.news-list {
    overflow: hidden;
  }
  
  .news-item {
    animation: scrollNews 20s linear infinite;
    animation-direction: reverse;
    color:white
  }
  
  @keyframes scrollNews {
    0% {
      transform: translateX(-70%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  /* .news-item-New {
    animation: scrollNewsNew 5s linear infinite;
    color:white
  }
  
  @keyframes scrollNewsNew {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  } */
  .button{
    background-color:white; 
    border-color:#cb1a2c;
    border-width:2px;
    border-style:solid
  }
  /* <!-- HTML !-->
<button class="button-71" role="button">Button 71</button> */

/* CSS */
.button-71 {
  background-color: #5794ca;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
  background-color:#c5192b;

}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 20px;
  }
}